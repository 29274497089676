import React from "react";
import styles from "./index.module.scss";
import rightArrow from "../../assets/rightArrow.svg";

const ProgressBar = (props: { items: number; currentStep: number }) => {
  const { items, currentStep } = props;

  return (
    <div className={styles.progress_bar_container}>
      <div className={styles.items_progress}>
        {Array.from({ length: items }, (_, item) => (
          <React.Fragment key={item}>
            {/* Step Circle */}
            <span
              className={`${styles.occasion_step} ${
                item < currentStep
                  ? styles.active
                  : item === currentStep
                  ? styles.current
                  : ""
              }`}
            >
              {item < currentStep && (
                <img
                  className={styles.innerRightIcon}
                  src={rightArrow}
                  alt="right-arrow"
                />
              )}
              {item === currentStep && (
                <span className={styles.innerActiveCircle}></span>
              )}
            </span>

            {/* Bar Separator */}
            {item < items - 1 && (
              <span
                className={`${styles.occasion_bar} ${
                  item < currentStep
                    ? styles.active
                    : item === currentStep - 1
                    ? styles.current
                    : ""
                }`}
              ></span>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
