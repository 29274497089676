const ApiEndpoints = {
  auth: {
    generateOtp: "/login",
    verifyOtp: "/verify",
    resendOtp: "/resend",
  },
  reservations: {
    editReservation: "/reserve/edit",
    createReservation: "/reserve/create",
    fetchTimeSlots: "/timeslots/fetch",
  },
  customers: {
    fetchCustomerDetails: "/diner/fetch",
  },
  settings: {
    fetchSettings: "/guestapp/settings/fetch",
  },
};
export default ApiEndpoints;
