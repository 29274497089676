import GoButton from "../../components/button";
import {
  FormKeys,
  ReservationState,
} from "../../container/ReservationContainer";
import { ButtonType } from "../../types";
import styles from "./index.module.scss";
import emailIcon from "../../assets/email.svg";
import { fetchGoData } from "../../utils/Methods";

interface SuccessScreenProps {
  changeScreen: (screen: ReservationState) => void;
  reservationData: any;
  resetData: () => void;
}
const SuccessScreen: React.FC<SuccessScreenProps> = (props) => {
  const { changeScreen, reservationData, resetData } = props;
  const handleWalkin = () => {
    changeScreen(ReservationState.PHONE_SCREEN);
    resetData();
  };
  const selectedOutlet = fetchGoData("selectedOutlet");

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div>
          <div className={styles.thanksText}>
            Thank you {reservationData[FormKeys.name]}!
          </div>
          <img className={styles.logo} src={emailIcon} alt="email-logo" />
        </div>
        <div className={styles.queueText}>
          You are placed at #{reservationData[FormKeys.queue]} in the queue
        </div>
        <div
        
          className={styles.pleaseWaitText}
        >
          Please wait! We’ll message you once your table is ready.
        </div>

        <div className={styles.addButton}>
          <GoButton
            onClickHandler={handleWalkin}
            customContainerStyle={{
              width: "50%",
              background: "#000000",
              color: "#FFFFFF !important",
              borderRadius: "12px",
              fontWeight: "600",
            }}
            type={ButtonType.outlined}
            title="Add New Walkin"
          />
        </div>
        <div className={styles.outletLogo}>
          {selectedOutlet?.brandLogo && (
            <img src={selectedOutlet?.brandLogo} alt="outlet-logo" />
          )}
        </div>
      </div>
    </div>
  );
};
export default SuccessScreen;
