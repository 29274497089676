import { useState } from "react";
import GoInputField from "../../components/input-field";
import {
  FormKeys,
  ReservationState,
} from "../../container/ReservationContainer";
import styles from "./index.module.scss";
import forwardIcon from "../../assets/forwardIcon.svg";

interface NameScreenProps {
  changeScreen: (screen: ReservationState, data?: any) => void;
  reservationData: any;
}
const NameScreen: React.FC<NameScreenProps> = ({
  changeScreen,
  reservationData,
}) => {
  const [name, setname] = useState(reservationData[FormKeys.name]);
  const handleClick = () => {
    changeScreen(ReservationState.GUEST_PICKER_SCREEN, {
      key: FormKeys.name,
      value: name,
    });
  };

  const [error, setError] = useState<any>(null);

  const keyPressHandler = (event: any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      if (name) {
        handleClick();
      } else {
        setError("Please enter your name to proceed further");
      }
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <GoInputField
          label="Enter your Name"
          value={name}
          onChange={setname}
          onKeyPress={keyPressHandler}
          maxLength={30}
          placeholder="Enter your first and last name"
          autofocus={!name}
          errorMsg={error}
        />
      </div>
      {name ? (
        <div className={styles.buttonContainer}>
          <div onClick={handleClick} className={styles.buttonBody}>
            <div className={styles.fwdIconContainer}>
              <img src={forwardIcon} alt="FwdIcon" />
            </div>
            <span className={styles.nextBtnLabel}>Next</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NameScreen;
