import { useEffect, useState } from "react";
import GoPhoneInput from "../../components/phone-input";
import {
  FormKeys,
  ReservationState,
} from "../../container/ReservationContainer";
import { CustomerService } from "../../utils/Api";
import ApiEndpoints from "../../utils/ApiEndpoints";
import { fetchGoData } from "../../utils/Methods";
import styles from "./index.module.scss";
import forwardIcon from "../../assets/forwardIcon.svg";

interface PhoneScreenProps {
  changeScreen: (screen: ReservationState, data?: any) => void;
  reservationData: any;
  setReservationData: (data: any) => void;
  isBack?: boolean;
}
const PhoneScreen: React.FC<PhoneScreenProps> = ({
  changeScreen,
  reservationData,
  setReservationData,
  isBack,
}) => {
  const countrySettings = fetchGoData("countrySettings");
  const [phone, setphone] = useState(reservationData[FormKeys.phone] || "");
  const [isLoading, setisLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const handleClick = async () => {
    seterrorMsg("");
    if (
      phone?.length !==
      countrySettings[0]?.countryCode?.toString()?.length +
        countrySettings[0]?.minPhoneNumberLength
    ) {
      seterrorMsg("Please enter valid phone number !");
    } else {
      (CustomerService as any).defaults.headers["x-access-token"] =
        fetchGoData("token");
      const selectedOutlet = fetchGoData("selectedOutlet");
      setisLoading(true);
      CustomerService.post(ApiEndpoints.customers.fetchCustomerDetails, {
        outletId: selectedOutlet?.id,
        guestPhone: phone,
      })
        .then(({ data }: any) => {
          setisLoading(false);
          const { customer } = data || {};
          if (customer) {
            let tempData: any = { ...reservationData };
            tempData[FormKeys.phone] = phone;
            tempData[FormKeys.name] = customer?.guestName;
            tempData[FormKeys.email] = customer.guestEmail;
            setReservationData(tempData);
            changeScreen(ReservationState.NAME_SCREEN);
          } else {
            changeScreen(ReservationState.NAME_SCREEN, {
              key: FormKeys.phone,
              value: phone,
            });
          }
        })
        .catch(() => {
          setisLoading(false);
          changeScreen(ReservationState.NAME_SCREEN, {
            key: FormKeys.phone,
            value: phone,
          });
        });
    }
  };
  useEffect(() => {
    if (
      !isBack &&
      phone?.length ===
        countrySettings[0]?.countryCode?.toString()?.length +
          countrySettings[0]?.minPhoneNumberLength
    ) {
      handleClick();
    }
  }, [phone, isBack]);
  return (
    <div className={styles.phnInputContainer}>
      <div>
        <GoPhoneInput
          label="Enter your mobile number"
          phone={phone}
          handleChange={setphone}
          initialCountry={countrySettings?.[0]?.shortcode}
          placeholder="Enter phone number"
          errorText={errorMsg}
          error={!!errorMsg}
        />
      </div>
      {phone ? (
        <div className={styles.buttonContainer}>
          <div onClick={handleClick} className={styles.buttonBody}>
            <div className={styles.fwdIconContainer}>
              <img src={forwardIcon} alt="FwdIcon" />
            </div>
            <span className={styles.nextBtnLabel}>Next</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PhoneScreen;
