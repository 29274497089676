import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import style from "./index.module.scss";
const inputStyle = {
  borderRadius: " 0 10px 10px 0",
  border: "2px solid #FFAC33",
  marginLeft: "58px",
  padding: "0 7px",
  height: "70px",
  flex: "1 1 auto",
  width: "100%",
};

const buttonStyle = {
  border: "2px solid #FFAC33",
  width: "58px",
  borderRadius: "10px 0  0 10px",
  background: "#fff",
};

const containerStyle = {
  width: "100%",
  display: "flex",
};
interface PhoneInputProps {
  phone: string;
  handleChange: (
    value: string,
    country: any,
    e: any,
    formattedValue: string
  ) => void;
  autoFocus?: boolean;
  label?: string;
  error?: boolean;
  errorText?: string;
  isDisabled?: boolean;
  initialCountry?: string;
  placeholder?: string;
}
export default function GoPhoneInput({
  phone,
  handleChange,
  autoFocus = true,
  label,
  error,
  errorText,
  isDisabled = false,
  initialCountry = "in",
  placeholder,
}: PhoneInputProps) {
  return (
    <div className={style.container}>
      {label && <span className={style.textInputLabel}>{label}</span>}

      <PhoneInput
        placeholder={placeholder}
        country={initialCountry?.toLowerCase()}
        countryCodeEditable={false}
        value={phone}
        onChange={handleChange}
        preferredCountries={["in"]}
        inputStyle={inputStyle}
        buttonStyle={buttonStyle}
        containerStyle={containerStyle}
        isValid={!error}
        inputProps={{
          autoFocus,
        }}
        disabled={isDisabled}
      />
      {error && errorText && (
        <span className={`${style.textInputLabel} ${style.errorText}`}>
          {errorText}
        </span>
      )}
    </div>
  );
}
