import { useState } from "react";
import GoGuestPicker from "../../components/guest-picker";
import forwardIcon from "../../assets/forwardIcon.svg";
import {
  FormKeys,
  ReservationState,
} from "../../container/ReservationContainer";
import styles from "./index.module.scss";
interface GuestPickerScreenProps {
  changeScreen: (screen: ReservationState, data?: any) => void;
  reservationData: any;
}
const GuestPickerScreen: React.FC<GuestPickerScreenProps> = ({
  changeScreen,
  reservationData,
}) => {
  const [guests, setguests] = useState<number>();
  const handleClick = () => {
    changeScreen(ReservationState.PREFERENCES_SCREEN, {
      key: FormKeys.noOfGuests,
      value: guests,
    });
  };
  return (
    <div className={styles.container}>
      <GoGuestPicker
        label="Select number of guests"
        selected={guests}
        onSelectNumber={setguests}
        onClick={handleClick}
      />
      {guests ? (
        <div className={styles.buttonContainer}>
          <div onClick={handleClick} className={styles.buttonBody}>
            <div className={styles.fwdIconContainer}>
              <img src={forwardIcon} alt="FwdIcon" />
            </div>
            <span className={styles.nextBtnLabel}>Next</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GuestPickerScreen;
