import styles from "./index.module.scss";

const AppLayout = ({ children }: any) => {
  return (
    <div className={styles.layout}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default AppLayout;
