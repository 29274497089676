import { OutlinedInput, InputAdornment, FormHelperText } from "@mui/material";
import styles from "./index.module.scss";

interface InputFieldProps {
  label?: string;
  value: string;
  errorMsg?: string;
  required?: boolean;
  onChange: (text: string) => void;
  placeholder?: string;
  startElement?: React.ReactElement;
  endElement?: React.ReactElement;
  customStyle?: any;
  multiline?: boolean;
  disabled?: boolean;
  maxLength?: number;
  type?: "text" | "number";
  autofocus?: boolean;
  onKeyPress?: any;
}
export default function GoInputField({
  label,
  value,
  onChange,
  errorMsg,
  required,
  placeholder,
  customStyle,
  startElement,
  endElement,
  multiline,
  disabled,
  maxLength,
  type = "text",
  autofocus = false,
  onKeyPress,
}: InputFieldProps) {
  return (
    <div className={styles.textFieldContainer}>
      {label && <span className={styles.labelText}>{label}</span>}
      <OutlinedInput
        id="outlined-adornment-weight"
        defaultValue={value}
        value={value}
        type={type}
        disabled={disabled}
        inputProps={{
          maxLength,
        }}
        sx={{
          fontSize: { xs: 16, sm: 24 },
          fontWeight: 500,
          ...customStyle,
        }}
        className={multiline ? styles.multilineInputBox : styles.inputBox}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        multiline={multiline}
        autoComplete="off"
        startAdornment={
          startElement ? (
            <InputAdornment position="start">{startElement}</InputAdornment>
          ) : null
        }
        endAdornment={
          endElement ? (
            <InputAdornment position="end">{endElement}</InputAdornment>
          ) : null
        }
        fullWidth
        {...(errorMsg && { error: true })}
        required={required}
        autoFocus={autofocus}
        onKeyPress={onKeyPress}
      />
      {errorMsg && (
        <FormHelperText error>
          <div className={styles.errorMessage}>{errorMsg}</div>
        </FormHelperText>
      )}
    </div>
  );
}
